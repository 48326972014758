.language {
  display: inline;
}
.heading {
  font-size: 20px;
}
#source {
  width: 100% !important;
  background: #cccccc52;
  height: 50vh;
  font-size: 15px;
  resize: vertical;
}
#input {
  background: #cccccc52;
  width: 100%;
  height: 10vh;
  resize: vertical;
  font-size: 15px;
}
#output {
  background: #cccccc52;
  width: 100% !important;
  height: 30vh;
  top: 7.6%;
  font-size: 15px;
  resize: vertical;
}
.language {
  width: 150px;
}
