.CodeBox, .highlight{
    padding: 0px 20px 0px 20px;
    border: 1px;
    border-color: black;
    background-color: rgb(240, 240, 240);
    font-family: 'Courier New', Courier, monospace;
    font-weight: 500;
    font-size: medium;
    color: rgb(29, 32, 97);
    text-align: left;
}

.SectionContent p{
    font-weight: 400;
    font-size: medium;
    color: black;
}
.SectionContent{
    margin-left: 40px;
}

.nav-tabs-wrapper h3{
    font-weight: bolder;
    background-color: rgb(240, 240, 240);
}
.nItem p, .nLink p{
    font-size: small;
    text-align: left;
    font-weight: 600;
}
.activeNLink p{
    color: blue;
    font-weight: 900;
    font-size:small;
    text-align: left;
}
.activeNItem p{
    color:darkslateblue;
    font-size:small;
    text-align: left;
    font-weight: 900;
}
.topHeader h6{
    background-color: darkslateblue;
    font-size: x-large;
    text-align: center;
    color: white;
    font-weight: 900;
}
.sho-icon{
    color: black;
    font-weight: 600;
}
.sho-icon-active{
    color: blue;
    font-weight: bolder;
}
.code-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}

